import { call, put, takeLatest } from "redux-saga/effects";
import { globalSearchPreRenderDataRequest, globalSearchPreRenderDataSuccess, globalSearchPreRenderDataFailure } from "../../slice/header/header-slice";

// import api from "../../config/api";
import axios from "axios";
const GLOBAL_SEARCH_PRE_DATA = "./assets/json-data/global-search.json";

//get global search pre render data
function* getGlobalSearchPreRenderData() {
  try {
    const response = yield call(axios.get, GLOBAL_SEARCH_PRE_DATA, {
      baseURL: "/"
    });
    yield put(globalSearchPreRenderDataSuccess(response.data));
  } catch (error) {
    yield put(globalSearchPreRenderDataFailure(error.message));
  }
}
export function* watchGlobalSearchPreRenderData() {
  yield takeLatest(globalSearchPreRenderDataRequest.type, getGlobalSearchPreRenderData);
}