import { call, put, takeEvery } from "redux-saga/effects";
import { getGiftMilesDropdownFailure, getGiftMilesDropdownRequest, getGiftMilesDropdownSuccess, getGiftMilesReceiptDownloadRequest, getGiftMilesReceiptDownloadFailed, getGiftMilesReceiptDownloadSuccess, giftMilesPaymentInitFailure, giftMilesPaymentInitRequest, giftMilesPaymentInitSuccess, reviewSummaryFailure, reviewSummaryRequest, reviewSummarySuccess } from "../slice/giftMilesSlice";
import api from "../../../config/api";
import { config } from "../../../config/global";
const accessToken = localStorage.getItem("accesstoken");
const memberId = sessionStorage.getItem("memberId");
const headerData = {
  mode: "no-cors",
  headers: {
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: accessToken,
    memberId
  }
};
const fetchDropdownData = async () => {
  const DROPDOWN_API_URL = config === null || config === void 0 ? void 0 : config.FETCH_GIFT_MILES_DROPDOWN;
  const response = await api.get(DROPDOWN_API_URL, headerData);
  return response;
};
const fetchReviewSummary = async payload => {
  const REVIEW_SUMMARY_URL = config === null || config === void 0 ? void 0 : config.REVIEW_SUMMARY_GIFT_MILES;
  let payloadData = {
    firstNameMiddleName: payload === null || payload === void 0 ? void 0 : payload.firstName,
    lastName: payload === null || payload === void 0 ? void 0 : payload.lastName,
    giftingMiles: payload === null || payload === void 0 ? void 0 : payload.giftingMiles,
    currency: payload === null || payload === void 0 ? void 0 : payload.currency,
    sponseeId: payload === null || payload === void 0 ? void 0 : payload.receipientROPId
  };
  const response = await api.post(REVIEW_SUMMARY_URL, payloadData, headerData);
  return response;
};
const fetchGiftMilesPaymentInit = async payload => {
  const response = await api.post(payload, headerData);
  return response;
};
const fetchGiftMilesReceiptDownload = async payload => {
  const DOWNLOAD_URL = config === null || config === void 0 ? void 0 : config.GIFT_MILES_DOWNLOAD_RECEIPT;
  const response = await api.post(DOWNLOAD_URL, payload, headerData);
  return response;
};
function* getDropdownData() {
  try {
    const response = yield call(fetchDropdownData);
    yield put(getGiftMilesDropdownSuccess(response === null || response === void 0 ? void 0 : response.data));
  } catch (error) {
    yield put(getGiftMilesDropdownFailure(error.message));
  }
}
function* getReviewSummary(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchReviewSummary, payload);
    yield put(reviewSummarySuccess(response === null || response === void 0 ? void 0 : response.data));
  } catch (error) {
    yield put(reviewSummaryFailure(error));
  }
}
function* getGiftMilesPaymentInit(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchGiftMilesPaymentInit, payload);
    yield put(giftMilesPaymentInitSuccess(response === null || response === void 0 ? void 0 : response.data));
  } catch (error) {
    yield put(giftMilesPaymentInitFailure(error));
  }
}
function* getGiftMilesReceiptDownload(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchGiftMilesReceiptDownload, payload);
    yield put(getGiftMilesReceiptDownloadSuccess(response === null || response === void 0 ? void 0 : response.data));
  } catch (error) {
    yield put(getGiftMilesReceiptDownloadFailed(error));
  }
}
export function* watchGiftMilesDropdownData() {
  yield takeEvery(getGiftMilesDropdownRequest.type, getDropdownData);
}
export function* watchGiftMilesReviewSummaryData() {
  yield takeEvery(reviewSummaryRequest.type, getReviewSummary);
}
export function* watchGiftMilesPaymentInit() {
  yield takeEvery(giftMilesPaymentInitRequest.type, getGiftMilesPaymentInit);
}
export function* watchGiftMilesReceiptDownload() {
  yield takeEvery(getGiftMilesReceiptDownloadRequest.type, getGiftMilesReceiptDownload);
}