import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { sendOTPPending, sendOTPSuccess, sendOTPFail } from "../slice/otpSlice";
// import axios from "axios";

const Login_API_URL = "profile/otp/submit";
//const Login_API_URL = "./json/submitOTP.json";

// const data ={
//     "otpRef": "ROP-103940",
//     "otpKey":9590
//    }

const sendLoginOTPData = async otpData => {
  const data = {
    otpRef: otpData.otpRef,
    otpKey: otpData.otpKey
  };
  const headerData = {
    headers: {
      accessToken: "".concat(sessionStorage.getItem("temptoken")),
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "Authorization"
    }
  };
  const response = await api.post(Login_API_URL, data, headerData);
  //const response = await axios.get(Login_API_URL);

  return response;
};
function* sendOTPData(action) {
  const {
    payload
  } = action;
  try {
    var _response$headers;
    const response = yield call(sendLoginOTPData, payload);
    localStorage.setItem("accesstoken", response === null || response === void 0 || (_response$headers = response.headers) === null || _response$headers === void 0 ? void 0 : _response$headers.authorization);
    localStorage.removeItem("followedFlights");
    localStorage.removeItem("recentFromAirport");
    localStorage.removeItem("recentToAirport");
    localStorage.removeItem("tripStatus");
    yield put(sendOTPSuccess(response.data));
  } catch (error) {
    yield put(sendOTPFail(error));
  }
}
export function* watchSendOTPData() {
  yield takeLatest(sendOTPPending.type, sendOTPData);
}