import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { helpFaqRequest, helpFaqSuccess, helpFaqFailure } from "../slice/helpFaqSlice";
import { config } from "../../../../src/config/global";
const Help_FAQ_URL = config === null || config === void 0 ? void 0 : config.HELP_FAQ;
const accessToken = sessionStorage.getItem("accesstoken");
const memberId = sessionStorage.getItem("memberId");
const HelpFaqData = async payload => {
  let data = {
    mode: "no-cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: accessToken,
      memberId
    },
    loader: true
  };
  let HelpFaqDataPayload = {
    memberId: payload === null || payload === void 0 ? void 0 : payload.memberId,
    feedbackType: payload === null || payload === void 0 ? void 0 : payload.feedbackType,
    description: payload === null || payload === void 0 ? void 0 : payload.description,
    isThaiServicesFeedback: payload === null || payload === void 0 ? void 0 : payload.isThaiServicesFeedback,
    isParticularFlight: payload === null || payload === void 0 ? void 0 : payload.isParticularFlight,
    customerContactNo: payload === null || payload === void 0 ? void 0 : payload.customerContactNo,
    customerEmail: payload === null || payload === void 0 ? void 0 : payload.customerEmail
  };
  const response = await api.post(Help_FAQ_URL, payload, data);
  console.log(payload, "line44");
  return response;
};
function* getHelpFaqData(action) {
  try {
    const {
      payload
    } = action;
    const response = yield call(HelpFaqData, payload);
    yield put(helpFaqSuccess(response.data));
  } catch (error) {
    yield put(helpFaqFailure(error));
  }
}
export function* watchHelpFaqDate() {
  yield takeLatest(helpFaqRequest.type, getHelpFaqData);
}